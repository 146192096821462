<template>
  <PageLoader v-if="pageLoading" :loading="pageLoading" />

  <div v-else class="container_margin">
    <h6><b>Statement</b></h6>
    <div class="card p-2">{{ title }}</div>

    <div
      v-if="searched_data.length !== 0"
      id="filtersAccordion"
      class="card mt-4"
    >
      <div
        id="filtersAccordionHeading"
        class="card-header"
        data-toggle="collapse"
        data-target="#filtersAccordionContent"
        aria-controls="filtersAccordionContent"
        aria-expanded="true"
      >
        <h6 class="font-weight-bold mb-0">
          Filters [{{ filtered_searched_data.length }} Case{{ filtered_searched_data.length > 1 ? "s" : "" }}]
          <span class="material-icons-outlined float-right cursor-pointer accordion-icon">
            expand_more
          </span>
        </h6>
      </div>

      <div
        id="filtersAccordionContent"
        class="collapse show"
        aria-labelledby="filtersAccordionHeading"
        data-parent="#filtersAccordion"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-5">
              <b class="text-blue font-14">{{ "Title" }}</b>
              <b-form-input
                class="mt-2"
                v-model="search"
                autocorrect="off"
                autocomplete="off"
                placeholder="Case Title"
                @input="filteringContent"
              ></b-form-input>
            </div>

            <div class="col-5">
              <b class="text-blue font-14">{{ "Abstract" }}</b>
              <b-form-input
                v-model="abstract"
                class="mt-2"
                autocorrect="off"
                autocomplete="off"
                placeholder="Abstract"
                @input="filteringContent"
              >
              </b-form-input>
            </div>

            <div class="col">
              <b class="text-blue font-14">{{ "Citation Count" }}</b>
              <vue-slider
                class="mt-3 me-3 ms-1"
                v-model="citeFilter"
                @change="filteringContent"
                :max="maxCite"
                :min="0"
                :interval="1"
                :tooltip-placement="['bottom', 'top']"
                :process="process"
                :tooltip="'always'"
              ></vue-slider>
            </div>
          </div>

          <div class="row my-4">
            <div class="col">
              <b class="text-blue font-14">{{ "Case Summary Status" }}</b>
              <multiselect
                class="mt-2"
                :maxHeight="150"
                :optionHeight="20"
                v-model="chosenFilterStatus"
                :options="filterStatus"
                track-by="name"
                label="name"
                placeholder="Choose Status(es)"
                @select="filteringContent"
                @remove="filteringContent"
                :multiple="true"
                :close-on-select="false"
                :searchable="false"
                selectLabel=""
                selectedLabel=""
                deselectLabel="Click to remove"
                :limit="2"
              >
              </multiselect>
            </div>

            <div
              v-if="filterCountryContents.length > 1"
              class="col"
            >
              <b class="text-blue font-14">{{ "Country" }}</b>
              <multiselect
                class="mt-2"
                :maxHeight="150"
                :optionHeight="20"
                v-model="chosenFilterCountry"
                :options="filterCountryContents"
                placeholder="Choose Country(s)"
                @select="filteringContent"
                @remove="filteringContent"
                :multiple="true"
                :close-on-select="false"
                :searchable="false"
                selectLabel=""
                selectedLabel=""
                deselectLabel="Click to remove"
                :limit="2"
              >
              </multiselect>
            </div>

            <div
              v-if="filterCountryContents.length > 1"
              class="col"
            >
              <b class="text-blue font-14">State</b>
              <multiselect
                class="mt-2"
                id="statesFilter"
                :maxHeight="250"
                :optionHeight="20"
                v-model="chosenFilterState"
                :options="filteredStatesContents"
                placeholder="Choose State(s)"
                @select="filteringContent"
                @remove="filteringContent"
                :multiple="true"
                :close-on-select="false"
                :searchable="false"
                selectLabel=""
                selectedLabel=""
                deselectLabel="Click to remove"
                :disabled="allowStateSelection"
                :limit="2"
              >
              </multiselect>
            </div>

            <div class="col">
              <b class="text-blue font-14">{{ "Relevancy Score" }}</b>
              <vue-slider
                class="mt-3 me-3 ms-1"
                v-model="value"
                @change="filteringContent"
                :marks="marks"
                :tooltip-placement="['bottom', 'top']"
                :process="process"
                :tooltip="'always'"
              ></vue-slider>
            </div>
          </div>

          <b class="text-blue font-14">{{ "Court Name" }}</b>
          <multiselect
            class="mt-2 mb-4"
            :maxHeight="180"
            :optionHeight="20"
            v-model="chosenFilterContents"
            :options="filterCourtContents"
            placeholder="Choose Court(s)"
            @select="filteringContent"
            @remove="filteringContent"
            :multiple="true"
            :close-on-select="false"
            :searchable="false"
            openDirection="bottom"
            selectLabel=""
            selectedLabel=""
            deselectLabel="Click to remove"
            :limit="5"
            :limit-text="customLimitText"
          >
          </multiselect>

          <div class="row">
            <div class="col-8">
              <b class="text-blue font-14">{{ "Decision Date" }}</b>
              <date-range-picker
                class="cursor-pointer mt-2"
                ref="picker"
                opens="right"
                v-model="dateRange"
                @update="filteringContent"
                :auto-apply="true"
                :showDropdowns="true"
                :locale-data="localeData"
              ></date-range-picker>
            </div>
            <div
              style="position: relative"
              class="col"
            > 
              <button
                style="position: absolute; bottom: .4rem; right: .8rem; height: 42.17px;"
                class="btn btn-primary"
                @click="clearFilter()"
              >
                {{ "Clear Filters" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h6 class="mt-4"><b>{{ "Case Law" }}</b></h6>

    <table class="table card border shadow-sm p-2 table-card">
      <thead>
        <tr>
          <th scope="col" width="5%">{{ "ID" }}</th>
          <th scope="col" width="70%">{{ "Case Law" }}</th>
          <th
            v-if="permissions.includes('nexlaw.summarize-jurisdiction')"
            scope="col"
            class="text-center"
            style="padding-right: 25px"
            width="25%"
          >
            {{ "Action" }}
          </th>
        </tr>
      </thead>

      <tbody v-if="searched_data.length === 0" class="container_margin">
        <div class="mb-3 m-auto" style="width: 200px">
          <img src="@/assets/noData.png" alt="No Data Image" />
        </div>

        <p class="text-center text-blue font-weight-bold">
          There is no case law found for the chosen Jurisdiction(s).
          <br><br>
          Would you like to:
        </p>

        <div class="row">
          <div class="col-2"></div>
          <div class="col">
            <button
              @click="searchJuri()"
              class="w-100 btn btn-primary"
            >
              {{ "Search in other Jurisdiction(s)" }}
            </button>
          </div>
          <div class="col">
            <button
              @click="searchLegis()"
              class="w-100 btn btn-primary-outlined"
            >
              {{ "Search for Legislation(s)" }}
            </button>
          </div>
          <div class="col-2"></div>
        </div>
      </tbody>

      <tbody>
        <div
          v-if="filtered_searched_data.length === 0"
          class="container_margin"
        >
          <div class="mb-3 m-auto" style="width: 200px">
            <img src="@/assets/noData.png" alt="No Data Image" />
          </div>
          <p class="text-center text-blue font-weight-bold">
            {{ "No Data, Kindly try different filters" }}
          </p>
        </div>

        <tr v-else v-for="(item, index) in filtered_searched_data" :key="index">
          <td width="5%"><b>{{ index + 1 }}</b></td>
          <td width="70%">
            <div class="row mb-3">
              <div class="col-9">
                <b>Case Title</b>
                <p class="mb-0">
                  <span class="d-inline me-2" v-html="(item.name?.trim() ?? '') != '' ? highlightCaseTitle[index] : '-'"></span>

                  <template v-if="item['cite-count'] > 10">
                    <!-- <span
                      :id="`citation-count-${index}`"
                      class="d-inline-flex no-wrap align-items-center citation"
                    > -->
                      <!-- <span
                        class="material-symbols-outlined font-18"
                        v-for="n in parseCiteCount(item['cite-count'], true)"
                        :key="n"
                      >mode_heat</span> -->
                    <!-- </span> -->
                    <span
                        :id="`citation-count-${index}`"
                        class="d-inline-flex no-wrap align-items-center citation"
                      >
                        {{ item["cite-count"] }} documents cited this judgement
                      </span>

                    <!-- <b-tooltip
                      :target="`citation-count-${index}`"
                      triggers="hover"
                      placement="bottom"
                      variant="secondary"
                      noninteractive
                    >{{ item["cite-count"] }} documents cited this judgement</b-tooltip> -->
                  </template>
                </p>
              </div>

              <div v-if="item.verify !== null" class="col-3">
                <div
                  :id="
                    item.verify >= 50
                      ? 'caseLawPill'
                      : item.verify >= 20
                      ? 'medCaseLawPill'
                      : 'lowCaseLawPill'
                  "
                  class="pill-container"
                >
                  <span
                    v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                    title="Relevancy Score"
                    class="pill float-right"
                  >
                    <b-icon icon="patch-check-fill"></b-icon>
                    {{ item.verify }}%
                  </span>
                </div>
              </div>

              <p
                v-if="
                  item.highlights !== '' &&
                  item.highlights !== null &&
                  item.highlights !== ' '
                "
                class="font-11 line-spacing mb-0"
                v-html="highlightTitle(index, item.highlights, item.topic)"
              ></p>
            </div>

            <b>Topic</b>
            <p v-if="item.topic == '' || item.topic == null">{{ `-` }}</p>
            <p>{{ item.topic }}</p>

            <b>Abstract</b>
            <p v-if="item.content === null">
              {{ "No Data" }}
            </p>
            <p
              v-else
              v-html="
                highlightTitle(
                  index,
                  highlightAbstract[index],
                  item.topic
                )
              "
            ></p>

            <div class="row">
              <div class="col">
                <b> Decision Date </b>
                <p>{{ item.date }}</p>
              </div>

              <div class="col">
                <b>Jurisdiction</b>
                <p>{{ item.type }}</p>
              </div>

              <div class="col">
                <b>Court Name</b>
                <p>{{ item.court }}</p>
              </div>
            </div>
          </td>

          <td class="text-center" width="25%">
            <button
              v-if="
                item.completedPerCentage == 'Not enough data' &&
                permissions.includes('nexlaw.summarize-jurisdiction')
              "
              class="btn btn-primary-outlined h-100"
              id="openButton"
              @click="SummarizeCase(item.id)"
            >
              {{ "Summarize Case" }}
            </button>

            <button
              v-else-if="item.completedPerCentage >= 100"
              class="btn btn-primary h-100"
              id="openButton"
              @click="openCase(item.id)"
            >
              {{ "View Summary" }}
            </button>

            <button
              v-else
              id="summarizingLoadingButton"
              class="btn btn-warning-outlined h-100"
              @click="$toast.warning('Still in progress.')"
            >
              <span v-if="item.completedPerCentage.toFixed(2) <= 0">
                <b>Summarizing:</b>
              </span>
              <span
                v-else-if="
                  item.completedPerCentage.toFixed(2) > 0 &&
                  item.completedPerCentage.toFixed(2) <= 33.33
                "
              >
                <b>Extracting key points:</b>
              </span>
              <span v-else><b>Extracting details:</b></span>
              {{ item.completedPerCentage.toFixed(2) }} %
              <b-spinner label="Spinning" class="spinner"></b-spinner>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { stopwords } from "./stopwords.js";
import { USstates } from "./50States.js";
import Auth from "@/store/Auth.js";
import CaseSearch from "@/store/CaseSearch.js";
import Multiselect from "vue-multiselect";

export default {
  components: {
    PageLoader,
    DateRangePicker,
    Multiselect,
  },
  data() {
    // let today = new Date();
    // today.setHours(0, 0, 0, 0);

    // let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    // let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    // thisMonthEnd.setHours(23, 59, 59, 999);

    // let lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    // let lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    // lastMonthEnd.setHours(23, 59, 59, 999);

    // let thisYearStart = new Date(today.getFullYear(), 0, 1);
    // let thisYearEnd = new Date(today.getFullYear(), 11, 31);
    // thisYearEnd.setHours(23, 59, 59, 999);

    // let lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
    // let lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);
    // lastYearEnd.setHours(23, 59, 59, 999);

    // let past2YearsStart = new Date(today.getFullYear() - 2, 0, 1);
    // let past2YearsEnd = new Date(today.getFullYear(), 11, 31);
    // past2YearsEnd.setHours(23, 59, 59, 999);

    // let past3YearsStart = new Date(today.getFullYear() - 3, 0, 1);
    // let past3YearsEnd = new Date(today.getFullYear(), 11, 31);
    // past3YearsEnd.setHours(23, 59, 59, 999);

    // let past5YearsStart = new Date(today.getFullYear() - 5, 0, 1);
    // let past5YearsEnd = new Date(today.getFullYear(), 11, 31);
    // past5YearsEnd.setHours(23, 59, 59, 999);

    return {
      permissions: localStorage.permissions,
      pageLoading: true,
      campaign: [],
      counts_in_country: [],
      searched_data: [],
      filtered_searched_data: [],
      filter: ["All", "Title", "Decision Date", "Court Name"],
      dateRange: {
        start: null,
        end: null,
      },
      localeData: {
        format: "dd/mm/yyyy", // Set the desired date format
      },
      // customRanges: {
      //   "This month": [thisMonthStart, thisMonthEnd],
      //   "Last month": [lastMonthStart, lastMonthEnd],
      //   "This year": [thisYearStart, thisYearEnd],
      //   "Last year": [lastYearStart, lastYearEnd],
      //   "2 years ago": [past2YearsStart, today],
      //   "3 years ago": [past3YearsStart, today],
      //   "5 years ago": [past5YearsStart, today],
      //   // "10 years ago": [past10YearsStart, past10YearsEnd],
      // },

      courtContents: [],
      filterCourtContents: [],
      filterCountryContents: [],
      filterStatus: [
        { id: "summarized", name: "Summarized" },
        { id: "summarizing", name: "Summarizing" },
        { id: "Not enough data", name: "Unsummarized" },
      ],
      filteredStatesContents: [],
      chosenFilterState: [],
      filterDateContents: [],
      chosenFilterContents: [],
      chosenFilterCountry: [],
      selectedCheckboxes: [],
      chosenFilterStatus: [],
      timeout_timer: null,
      citeFilter: [0, 0],

      value: [0, 100], // Relevancy filter
      process: (dotsPos) => [
        [dotsPos[0], dotsPos[1], { backgroundColor: "#094890" }],
      ],
      marks: {
        0: {
          label: "Low",
          style: {
            width: "12px",
            height: "12px",
            display: "block",
            backgroundColor: "#fc3c0d",
            transform: "translate(-2px, -4px)",
          },
          labelStyle: {
            color: "#fc3c0d",
          },
        },

        20: {
          label: "Medium",
          style: {
            width: "12px",
            height: "12px",
            display: "block",
            backgroundColor: "#ffae00",
            transform: "translate(-2px, -4px)",
          },
          labelStyle: {
            color: "#ffae00",
          },
        },

        50: {
          label: "High",
          style: {
            width: "12px",
            height: "12px",
            display: "block",
            backgroundColor: "#00c441",
            transform: "translate(-2px, -4px)",
          },
          labelStyle: {
            color: "#00c441",
          },
        },
      },
      
      timer_count: 0,
      search: "",
      abstract: "",
      title: "",
      searchType: "",
    };
  },

  computed: {
    allowStateSelection() {
      if (!this.filteredStatesContents.length > 0) {
        this.chosenFilterState = [];
      }
      return !this.filteredStatesContents.length > 0;
    },

    highlightCaseTitle() {
      return this.filtered_searched_data.map((data) => {
        if (this.search && this.search !== "") {
          return data.name.replace(
            new RegExp(`(${this.search.toLowerCase()})`, "gi"),
            (match) => `<span style="background-color: orange">${match}</span>`
          );
        }
        return data.name;
      });
    },

    highlightAbstract() {
      return this.filtered_searched_data.map((data) => {
        if (this.abstract && this.abstract !== "") {
          return data.content.replace(
            new RegExp(`(${this.abstract.toLowerCase()})`, "gi"),
            (match) => `<span style="background-color: orange">${match}</span>`
          );
        }
        return data.content;
      });
    },

    maxCite() {
      return Math.ceil(Math.max(
        ...this.searched_data
          .filter(data => data["cite-count"] > 10)
          .map(data => this.parseCiteCount(data["cite-count"]))
      ) / 10) * 10;
    },
  },

  methods: {
    customLimitText(count) {
      // Customize the text when the limit is reached using a function
      return `and ${count} more court(s)`;
    },

    parseCiteCount(cc, limit = null) {
      return limit ? Math.min(Math.floor(cc / 10), 5) : Math.floor(cc/10);
    },

    searchJuri() {
      var caseSearch_data = {};
      caseSearch_data = {
        // search_type: this.searchType,
        statement: this.title,
      };
      // console.log(caseSearch_data);

      localStorage.setItem("caseSearch_data", JSON.stringify(caseSearch_data));

      this.$router.push({
        name: "SearchResult",
      });
    },

    searchLegis() {
      var legisSearch_data = {};
      legisSearch_data = {
        // search_type: this.searchType,
        statement: this.title,
      };
      // console.log(legisSearch_data);

      localStorage.setItem(
        "legisSearch_data",
        JSON.stringify(legisSearch_data)
      );

      this.$router.push("/Legislation_Search/Search_Result");
    },

    clearFilter() {
      this.filtered_searched_data = this.searched_data;
      this.filterCourtContents = this.courtContents.map((obj) => obj.court);
      this.filteredStatesContents = [];
      this.chosenFilterContents = [];
      this.chosenFilterStatus = [];
      this.chosenFilterCountry = [];
      this.chosenFilterState = [];
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.search = "";
      this.abstract = "";
      this.value = [0, 100];
    },

    filteringContent() {
      // console.log("filteringContent is triggered");

      // filter states (currently only for US)
      const statesMapping = {
        "United States": USstates,
      };
      const chosenCountry = this.chosenFilterCountry[0];
      this.filteredStatesContents =
        this.chosenFilterCountry.length === 1 && statesMapping[chosenCountry]
          ? [
              ...statesMapping[chosenCountry].filter((state) => {
                // Check if the state is part of any element.court in this.search_data
                return this.searched_data.some((element) =>
                  element.court.toLowerCase().includes(state.toLowerCase())
                );
              }),
              "Others",
            ]
          : [];

      // filter courts
      if (
        this.chosenFilterCountry.length === 1 &&
        this.chosenFilterState.includes("Others")
      ) {
        this.filterCourtContents = [
          // general courts when 'Others' is selected
          ...this.courtContents
            .filter((obj) => obj.type == chosenCountry)
            .filter(
              (obj) =>
                !statesMapping[chosenCountry].some((state) =>
                  obj.court.toLowerCase().includes(state.toLowerCase())
                )
            )
            .map((obj) => obj.court),

          // filter base on state names in court
          ...this.courtContents
            .filter((obj) => obj.type == chosenCountry)
            .filter((obj) => {
              if (this.chosenFilterState.length > 0) {
                return this.chosenFilterState.some((state) =>
                  obj.court.toLowerCase().includes(state.toLowerCase())
                );
              } else return true;
            })
            .map((obj) => obj.court),
        ];
        // console.log(this.filterCourtContents);
      } else if (this.chosenFilterCountry.length === 1) {
        this.filterCourtContents = [
          // filter base on state names in court
          ...this.courtContents
            .filter((obj) => obj.type == chosenCountry)
            .filter((obj) => {
              if (this.chosenFilterState.length > 0) {
                return this.chosenFilterState.some((state) =>
                  obj.court.toLowerCase().includes(state.toLowerCase())
                );
              } else return true;
            })
            .map((obj) => obj.court),
        ];
      } else {
        // display all present courts
        this.filterCourtContents = this.courtContents.map((obj) => obj.court);
      }

      // filter searched data
      this.filtered_searched_data = this.searched_data;

      // filter invalid data
      if (this.chosenFilterStatus.length > 0) {
        this.filtered_searched_data = this.filtered_searched_data.filter(
          (element) =>
            this.chosenFilterStatus.some((item) =>
              item.id.includes(element.completedPerCentage)
            )
        );
      }

      // apply summary status filter
      var filtered_content = [];
      Object.values(this.chosenFilterStatus).forEach((item) => {
        if (item.id.includes("summarized")) {
          this.searched_data.forEach((element) => {
            // console.log(element.completedPerCentage)
            if (element.completedPerCentage >= 100) {
              // console.log(element.completedPerCentage)
              filtered_content.push(element);
            }
            // console.log("Filtered Content Summarizing:", filtered_content);
          });

          filtered_content.forEach((element) => {
            this.filtered_searched_data.push(element);
          });
          // console.log("Filtered Content Summarizing:", filtered_content)
          // console.log('after filtering summarizing', this.filtered_searched_data)
        }
        if (item.id.includes("summarizing")) {
          this.searched_data.forEach((element) => {
            // console.log(element.completedPerCentage)
            if (
              element.completedPerCentage != "Not enough data" &&
              element.completedPerCentage < 100
            ) {
              // console.log(element.completedPerCentage)
              filtered_content.push(element);
            }
            // console.log("Filtered Content Summarizing:", filtered_content);
          });

          filtered_content.forEach((element) => {
            this.filtered_searched_data.push(element);
          });
          filtered_content = [];
          // console.log('after filtering summarizing', this.filtered_searched_data)
        }
      });

      // apply court filter
      if (this.chosenFilterContents.length > 0) {
        this.filtered_searched_data = this.filtered_searched_data.filter(
          (element) => this.chosenFilterContents.includes(element.court)
        );
      }

      // apply country filter
      if (this.chosenFilterCountry.length > 0) {
        this.filtered_searched_data = this.filtered_searched_data.filter(
          (element) => this.chosenFilterCountry.includes(element.type)
        );
      }

      // apply state filter
      if (this.chosenFilterState.includes("Others")) {
        this.filtered_searched_data = this.filtered_searched_data.filter(
          (element) => this.filterCourtContents.includes(element.court)
        );
      } else if (this.chosenFilterState.length > 0) {
        this.filtered_searched_data = this.filtered_searched_data.filter(
          (element) =>
            this.chosenFilterState.some((state) =>
              element.court.toLowerCase().includes(state.toLowerCase())
            )
        );
      }

      var range = this.dateRange;
      // apply date filter
      if (range && range.startDate && range.endDate) {
        const fromDate = moment(range.startDate).format("YYYY-MM-DD");
        const toDate = moment(range.endDate).format("YYYY-MM-DD");

        this.filtered_searched_data = this.filtered_searched_data.filter(
          (element) => {
            // Date filter logic
            const elementDate = moment(element.date).format("YYYY-MM-DD");
            // console.log("Element: ", elementDate);

            if (!fromDate && !toDate) {
              // Both dates are null, so return true to include all data.
              return true;
            }

            if (!fromDate) {
              // Only "to date" is selected, so filter based on "to date" alone.
              return elementDate <= toDate;
            }

            if (!toDate) {
              // Only "from date" is selected, so filter based on "from date" alone.
              return elementDate >= fromDate;
            }

            return elementDate >= fromDate && elementDate <= toDate;
          }
        );
      }

      // apply title filer
      if (this.search.length > 0) {
        this.filtered_searched_data = this.filtered_searched_data.filter(
          (element) =>
            element.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }

      // apply abstract filer
      if (this.abstract.length > 0) {
        this.filtered_searched_data = this.filtered_searched_data.filter(
          (element) =>
            element.content.toLowerCase().includes(this.abstract.toLowerCase())
        );
      }

      // apply citation count filter
      this.filtered_searched_data = this.filtered_searched_data.filter(
        (item) => {
          return item["cite-count"] >= this.citeFilter[0] && item["cite-count"] <= this.citeFilter[1];
        }
      );

      // apply relavancy filter
      this.filtered_searched_data = this.filtered_searched_data.filter(
        (item) => {
          return item.verify >= this.value[0] && item.verify <= this.value[1];
        }
      );

      // console.log(this.filtered_searched_data);
    },

    openCase(data) {
      this.$router.push({
        name: "Case_Law_Search_CaseSummary",
        params: {
          id: data,
        },
      });
    },

    highlightTitle(index, content, topic) {
      if (content === this.filtered_searched_data[index].highlights) {
        content = `...${content}...`;
      }

      if (topic !== null) {
        var topicWordsRaw = topic.replace(/[^\w\s]/g, "").split(" ");
        var titleWordsRaw = this.title.replace(/[^\w\s]/g, "").split(" ");
        // console.log("1", topicWordsRaw);

        var topicWords = topicWordsRaw.filter((word) => word !== "");
        var titleWords = titleWordsRaw.filter((word) => word !== "");
        // console.log("2", topicWords);

        topicWords = topicWords.map((word) => word.toLowerCase());
        titleWords = titleWords.map((word) => word.toLowerCase());

        const filteredTopicWords = topicWords.filter(
          (word) => !stopwords.includes(word)
        );
        const filteredTitleWords = titleWords.filter(
          (word) => !stopwords.includes(word)
        );
        // console.log("3", filteredTopicWords);

        const mergedArray = filteredTopicWords.concat(
          filteredTitleWords.filter(
            (word) => !filteredTopicWords.includes(word)
          )
        );
        // console.log("Neww arr:", mergedArray);

        const pattern = new RegExp(`\\b(${mergedArray.join("|")})\\b`, "gi");
        // console.log("4", pattern);

        return content.replace(
          pattern,
          (match) => `<span style="background-color: yellow">${match}</span>`
        );
      }
    },

    highlightText(text, reference) {
      if (reference?.length ?? false === 0) return;
      // console.log(reference, this.search);

      return text.replace(
        new RegExp(`\\b(${reference.toLowerCase()})\\b`, "gi"),
        (match) => `<span style="background-color: yellow">${match}</span>`
      );
    },

    SummarizeCase(data) {
      CaseSearch.SummarizeCase(data)
        .then((response) => {
          Auth.getMe().then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));

            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (
              !userInfo ||
              userInfo.nexlaw_credits == undefined ||
              userInfo.nexlaw_credits == "undefined" ||
              userInfo.nexlaw_credits == null ||
              userInfo.nexlaw_credits == "null"
            ) {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("userInfo");
              this.$router.push("/login");
            }
          });
          this.$toast.success(response.data.message);
          var arr =
            this.filtered_searched_data[
              this.filtered_searched_data.findIndex((x) => x.id === data)
            ];

          arr.completedPerCentage = response.data.data.completedPerCentage;

          this.filtered_searched_data.splice(
            this.filtered_searched_data.findIndex((x) => x.id === data),
            1,
            arr
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    CaseSearch.GetJurisdiction(this.$route.params.id)
      .then((response) => {
        const DATA = response.data.data;
        this.pageLoading = false;
        this.campaign = DATA;

        try {
          // const jsonObject = JSON.parse(response.data.data.statement);
          // console.log(jsonObject);
          this.title = JSON.parse(DATA.statement).join(", ");
        } catch (error) {
          // console.error("Error parsing JSON:", error.message);
          this.title = DATA.statement;
        }

        try {
          // const jsonObject2 = JSON.parse(response.data.data.type);
          // console.log(jsonObject2);
          this.searchType = JSON.parse(DATA.type);
        } catch (error) {
          // console.error("Error parsing JSON:", error.message);
          this.searchType = DATA.type;
        }

        DATA.jurisdictions.forEach((element) => {
          element.showFullContent = false;

          if (element.content !== "Can't Read File") {
            this.searched_data.push(element);
            this.filtered_searched_data.push(element);
          }

          this.courtContents = Array.from(
            new Set(
              this.searched_data.map((obj) => obj["type"] + "#" + obj["court"])
            )
          ).map((str) => {
            const [type, court] = str.split("#");
            return { type, court };
          });
          this.filterCourtContents = this.courtContents.map((obj) => obj.court);

          this.filterCountryContents = Array.from(
            new Set(this.searched_data.map((obj) => obj["type"]))
          );

          this.filterDateContents = Array.from(
            new Set(this.searched_data.map((obj) => obj["date"]))
          );

          this.filterDateContents.sort(function (a, b) {
            var dateA = new Date(a),
              dateB = new Date(b);
            return dateB < dateA ? -1 : 1;
          });
          // console.log(this.searched_data)
          // console.log(this.filtered_searched_data)
        });

        this.searched_data.sort((a, b) => {
          return b.verify - a.verify;
        });

        this.filtered_searched_data.sort((a, b) => {
          return b.verify - a.verify;
        });

        this.citeFilter[1] = this.maxCite;

        // console.log("type", this.filterCountryContents);
        // console.log("Court: ", this.filterCourtContents);
        // console.log(this.searched_data);
      })
      .catch((error) => {
        console.log(error);
      });

    var me = this;
    function loop() {
      me.timer_count++;
      me.timeout_timer = setTimeout(() => {
        if (!me.$route.params.id) return;
        
        CaseSearch.GetJurisdiction(me.$route.params.id)
          .then((response) => {
            response.data.data.jurisdictions.forEach((element) => {
              me.filtered_searched_data.forEach((element2) => {
                if (element.id == element2.id) {
                  element2.completedPerCentage = element.completedPerCentage;
                }
              });
            });
          })
          .finally(() => {
            if (me.timer_count <= 15) {
              loop();
            }
          })

          .catch((error) => {
            console.log(error);
          });
      }, 20000);
    }
    loop();
  },

  destroyed() {
    clearTimeout(this.timeout_timer);
  },

  beforeDestroy() {
    this.timer_count = 0;
    clearTimeout(this.timeout_timer);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.highlight {
  background-color: yellow;
  /* You can customize the highlight color */
}

/* table */
.table-card {
  overflow-x: auto;
  width: auto !important;
}

.table {
  border: none;
  background: white;
  table-layout: fixed;
  width: auto !important;
}

tbody {
  display: block;
  max-height: 650px;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}

.container_margin {
  padding: 2rem;
}

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}

/* @media only screen and (max-width: 1024px) {
    .btn {
      padding-bottom: 52px;
    }
  }
  
  @media only screen and (max-width: 1440px) {
    .btn-primary-outlined {
      padding-bottom: 52px;
    }
  } */

.form-control {
  font-size: 14px;
}

#summarizingLoadingButton {
  padding-left: 5px;
  padding-right: 5px;
  width: 180px;
}

.vue-daterange-picker {
  width: 100%;
}

.accordion-label {
  cursor: pointer;
  display: block;
}

.accordion-icon {
  transition: transform 0.3s;
}

.card-header[aria-expanded=false] .accordion-icon {
  transform: rotate(-180deg);
}

#openButton {
  padding-left: 5px;
  padding-right: 5px;
  width: 150px;
}

.citation {
  padding: 1px 3px;
  border-radius: 10px;
  color: orange;
  /* background-color: orange; */
}
</style>
